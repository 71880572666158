.form-wrap.form-builder .frmb .form-field .form-group{
    display: flex;
    flex-direction: row;
}
.form-wrap.form-builder .frmb .prev-holder{
    display: flex !important;
    flex-direction: row;
}
.query-icon, .link-img{
    cursor: pointer;
}
.query-icon label{
    display: none;
}
.query-img, .link-img{
    width: 20px;
    height: 20px;
    margin-right: 15px;
    margin-top: 6px;
}
.fb-editor-conditional{
    background-color: #ccc;
    margin: 100px;
}
.noclick{
    pointer-events: none;
    opacity: 0.6;
  }