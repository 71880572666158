body,
html,
#root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #000000 !important;
}
.menu-active .MuiListItemButton-gutters {
  background: #c6c6c6 !important;
  font-weight: bold;
}
.MuiListItemButton-gutters.menu-active {
  background: #c6c6c6 !important;
  font-weight: bold;
}
.menu-list-item a {
  text-decoration: none;
  color: #000000;
}
.css-zhw3j5-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  margin-top: -24px;
}
.login-form .MuiInputBase-input {
  background-color: #fff;
}
.login-form .MuiFormLabel-filled {
  color: #FFF;
}
.css-k45a3k-MuiToolbar-root {
  min-height: 45px !important;
}
.css-1a3ih8v-MuiToolbar-root {
  min-height: 40px !important;
}