.form-status{
    border-radius: 10px;
    border: 1px #ccc solid;
    width: auto;
    padding: 10px;
}
.form-status.pending, .form-status.open, .form-status.draft{
    background-color: #ffa733;
}
.form-status.in-progress, .form-status.published{
    background-color: #428CC8;
}
.form-status.completed, .form-status.approved, .form-status.resolved{
    background-color: #4EE676;
}
.form-status.rejected{
    background-color: #E25141;
}